import React, { useEffect } from "react";
import calorias from "../../assets/images/calorias 1.png";
import gorduras from "../../assets/images/gorduras 1.png";
import proteinas from "../../assets/images/proteinas 1.png";
import carboidratos from "../../assets/images/carboidratos 1.png";
import agua from "../../assets/images/water 1.png";
import imc from "../../assets/images/imc 1.png";

export default function Video() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://scripts.converteai.net/eafa96af-3b9a-4c97-bda7-b94aebd35e4a/players/65e3c22724757e0008558b16/player.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const infoscontainers = getInfosData().map((info) => {
    return (
      <InfosContainer
        key={info.id}
        img={info.img}
        title={info.title}
        info={info.info}
      />
    );
  });

  return (
    <section className="px-4 py-11 content-height flex flex-col items-center justify-center xl:px-8">
      <div className="w-full max-w-5xl text-customBlack-black pb-10 flex flex-col gap-7">
        <article className="flex flex-col items-center gap-4">
          <h1 className="text-center font-bold text-xl md:text-4xl max-w-[40ch]">
            Pronto! Já calculamos o{" "}
            <mark className="text-secondary font-bold bg-transparent">
              {" "}
              Truque da Cerveja{" "}
            </mark>{" "}
            personalizada para você. <br /> Clique no botão abaixo para acessar:
          </h1>

          <article className="w-full mt-6">
            <div
              id="vid_65e3c22724757e0008558b16"
              style={{
                position: "relative",
                width: "100%",
                paddingTop: "56.25%",
                overflow: "hidden",
              }}
            >
              <img
                id="thumb_65e3c22724757e0008558b16"
                src="https://images.converteai.net/eafa96af-3b9a-4c97-bda7-b94aebd35e4a/players/65e3c22724757e0008558b16/thumbnail.jpg"
                alt="video thumbnail"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  display: "block",
                }}
              />
              <div
                id="backdrop_65e3c22724757e0008558b16"
                style={{
                  position: "absolute",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  WebkitBackdropFilter: "blur(5px)",
                  backdropFilter: "blur(5px)",
                }}
              ></div>
            </div>
          </article>

          <a
            href="#"
            className="py-3 pulse-animation text-white xl:py-6 w-full max-w-96 px-6 xl:px-7 rounded-2xl bg-secondary flex flex-col items-center justify-center font-medium text-base mt-1 cursor-pointer transition-all duration-200 hover:bg-tertiary "
            type="submit"
          >
            Descubra a receita do truque da cerveja!
          </a>
        </article>

        <article className="flex flex-col items-center gap-5">
          <h2 className="text-center font-bold text-xl md:text-2xl">
            Informações importantes sobre o seu plano!
          </h2>

          <div className="flex flex-wrap gap-4 justify-center items-center">
            {infoscontainers}
          </div>

          <a
            href="#"
            className="py-3 pulse-animation text-white xl:py-6 w-full max-w-96 px-6 xl:px-7 rounded-2xl bg-secondary flex flex-col items-center justify-center font-medium text-base mt-1 cursor-pointer transition-all duration-200 hover:bg-tertiary "
            type="submit"
          >
            Descubra a receita do truque da cerveja!
          </a>
        </article>
      </div>
    </section>
  );
}

function InfosContainer(props) {
  return (
    <article className="w-[8.5rem] h-[10.2rem] rounded-2xl border border-[rgba(0,0,0,.3)] flex flex-col items-center gap-4 py-4 px-3">
      <img src={props.img} alt="" className="w-[52.55px] h-[55.25px]" />

      <article className="flex flex-col items-center">
        <h3 className="font-bold text-sm text-center">{props.title}</h3>
        <mark className="font-bold text-base text-secondary text-center">
          {props.info}
        </mark>
      </article>
    </article>
  );
}

function getInfosData() {
  const nutritionData = JSON.parse(
    window.localStorage.getItem("nutritionData")
  );

  return [
    {
      id: 1,
      img: calorias,
      title: "Calorias diárias",
      info: nutritionData.dci,
    },
    {
      id: 2,
      img: gorduras,
      title: "Gorduras",
      info: `${nutritionData.fatIntakeGrams}g`,
    },
    {
      id: 3,
      img: proteinas,
      title: "Proteínas diárias",
      info: `${nutritionData.remainingCalories}g`,
    },
    {
      id: 4,
      img: calorias,
      title: "Carboídratos diários",
      info: `${nutritionData.carbohydrateIntakeGrams}g`,
    },
    {
      id: 5,
      img: agua,
      title: "Consumo diário de água",
      info: `${nutritionData.waterIntakeMax}L`,
    },
    {
      id: 6,
      img: imc,
      title: "Seu IMC",
      info: `${nutritionData.bmi}`,
    },
  ];
}
