import { useState } from "react";
import beer from "../../assets/images/beer.png";

export default function Hero(props) {
  const [formData, setFormData] = useState(() => {
    return {
      data: {
        age: "",
        height: "",
        weight: "",
      },

      status: {
        errors: {
          ageError: {
            error: false,
            msg: "",
          },
          heightError: {
            error: false,
            msg: "",
          },
          weightError: {
            error: false,
            msg: "",
          },
        },
      },
    };
  });

  return (
    <section className="px-4 bg-primary content-height flex flex-col items-center justify-center xl:px-8">
      <div className="max-w-md m-auto xl:max-w-[80rem]">
        <div className="text-customBlack-black py-11 flex flex-col gap-3 xl:flex-row-reverse xl:gap-20 xl:py-20">
          <picture>
            <img
              src={beer}
              alt="Imagen de chá de canela."
              className="w-full object-cover xl:h-full rounded-xl"
            />
          </picture>

          <article className="flex flex-col gap-2 xl:gap-4">
            <h1 className="font-bold text-2xl xl:text-5xl xl:max-w-[20ch] text-secondary">
              Seja bem-vindo ao Aplicativo Oficial do truque da cerveja
            </h1>

            <p className="font-normal text-base xl:max-w-[45ch] ">
              Preencha seus dados nos campos abaixo para que o aplicativo
              calcule a sua receita de cerveja personalizada.
            </p>

            <form
              className="flex flex-col gap-3 w-full mt-3"
              noValidate
              onSubmit={(event) =>
                handleSubmitForm(event, formData, setFormData, props.setIsVideo)
              }
            >
              <div className="flex flex-col gap-1">
                <label className="font-medium text-base " htmlFor="age">
                  Idade:
                </label>
                <div className="flex flex-col gap-1">
                  <input
                    type="number"
                    name="age"
                    id="age"
                    value={formData.data.age}
                    onChange={(event) =>
                      handleChangeFormValues(event, setFormData)
                    }
                    className="w-full rounded-xl px-3 py-2 text-customBlack-black text-base border border-[rgba(0,0,0,.3)]"
                  />
                  {formData.status.errors.ageError.error && (
                    <p className="text-red-600">
                      {formData.status.errors.ageError.msg}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <label className="font-medium text-base " htmlFor="height">
                  Altura (em cm):
                </label>
                <div className="flex flex-col gap-1">
                  <input
                    type="number"
                    name="height"
                    id="height"
                    value={formData.data.height}
                    onChange={(event) =>
                      handleChangeFormValues(event, setFormData)
                    }
                    className="w-full rounded-xl px-3 py-2 text-customBlack-black text-base border border-[rgba(0,0,0,.3)]"
                  />

                  {formData.status.errors.heightError.error && (
                    <p className="text-red-600">
                      {formData.status.errors.heightError.msg}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <label className="font-medium text-base " htmlFor="weight">
                  Peso atual (em Kg):
                </label>
                <div className="flex flex-col gap-1">
                  <input
                    type="number"
                    name="weight"
                    id="weight"
                    value={formData.data.weight}
                    onChange={(event) =>
                      handleChangeFormValues(event, setFormData)
                    }
                    className="w-full rounded-xl px-3 py-2 text-customBlack-black text-base border border-[rgba(0,0,0,.3)]"
                  />

                  {formData.status.errors.weightError.error && (
                    <p className="text-red-600">
                      {formData.status.errors.weightError.msg}
                    </p>
                  )}
                </div>
              </div>

              <button
                className="py-3 xl:py-4 px-6 rounded-2xl text-white bg-secondary flex flex-col items-center justify-center font-medium text-base mt-1 cursor-pointer transition-all duration-200 hover:bg-tertiary "
                type="submit"
              >
                Descubra a receita do truque da cerveja!
              </button>
            </form>
          </article>
        </div>
      </div>
    </section>
  );
}

function handleSubmitForm(event, formData, setFormData, setIsVideo) {
  event.preventDefault();

  const error = validateFormData(formData, setFormData);

  if (error) {
    return;
  }

  const age = formData.data.age;
  const height = formData.data.height;
  const weight = formData.data.weight;

  // DCI for male = 88.362 + (13.397 × weight in kg) + (4.799 × height in cm) - (5.677 × age in years) //
  const dci = Math.round(
    88.382 + 13.397 * weight + 4.799 * height - 5.677 * age
  );

  // using 25% //
  const fatPercentage = 0.25;
  const fatIntakeCalories = fatPercentage * dci;
  const fatIntakeGrams = Math.round(fatIntakeCalories / 9);

  // Daily Protein Requirement calculation (0.8 grams per kilogram of body weight) //
  const proteinIntakeGrams = 0.8 * weight;
  const remainingCalories = Math.round(
    dci - fatIntakeCalories - proteinIntakeGrams * 4
  );

  // Daily Carbohydrate Requirement //
  const carbohydrateIntakeCalories = remainingCalories;
  const carbohydrateIntakeGrams = Math.round(carbohydrateIntakeCalories / 4);

  // Daily Water Intake calculation (30-35 milliliters per kilogram of body weight) //
  const waterIntakeMax = Math.round(35 * weight) / 1000;

  // BMI calculation
  const heightInMeters = height / 100;
  const bmi = parseFloat(
    (weight / (heightInMeters * heightInMeters)).toFixed(1)
  );

  const nutritionData = {
    dci,
    fatIntakeGrams,
    remainingCalories,
    carbohydrateIntakeGrams,
    waterIntakeMax,
    bmi,
  };

  window.localStorage.setItem("nutritionData", JSON.stringify(nutritionData));

  window.location.replace("https://apppoficialltruquedacerveja.com");
}

function validateFormData(formData, setFormData) {
  const notANumberError = "Por favor, insira um número válido.";

  let error = false;

  const age = parseInt(formData.data.age);
  const height = parseInt(formData.data.height);
  const weight = parseInt(formData.data.weight);

  const newFormData = { ...formData };

  if (Number.isNaN(age)) {
    newFormData.status.errors.ageError = {
      error: true,
      msg: notANumberError,
    };

    error = true;
  } else {
    newFormData.status.errors.ageError = {
      error: false,
    };
  }

  if (Number.isNaN(height)) {
    newFormData.status.errors.heightError = {
      error: true,
      msg: notANumberError,
    };
    error = true;
  } else {
    newFormData.status.errors.heightError = {
      error: false,
    };
  }

  if (Number.isNaN(weight)) {
    newFormData.status.errors.weightError = {
      error: true,
      msg: notANumberError,
    };
    error = true;
  } else {
    newFormData.status.errors.weightError = {
      error: true,
    };
  }

  setFormData(() => newFormData);

  return error;
}

function handleChangeFormValues(event, setFormData) {
  const target = event.target;
  const targetName = target.name;
  const targetValue = target.value;

  setFormData((prevFormData) => {
    return {
      ...prevFormData,
      data: {
        ...prevFormData.data,
        [targetName]: targetValue,
      },
    };
  });
}
